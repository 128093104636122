import "./src/styles/global.css";

function checkThings() {
  const thingsToWaitFor = document.getElementsByClassName("brand-wait");
  let result = 0;
  for (let i = 0; i < thingsToWaitFor.length; i++) {
    result++;
  }
  return result;
}
function isPageReady() {
  let interval = setInterval(frame, 1000);
  const loader = document.getElementById("___loader");

  //  frame();

  function frame() {
    if (checkThings() === 0) {
      clearInterval(interval);
      loader?.classList?.add("brand-loader-hide");
    }
  }
}

// Called when the initial (but not subsequent) render of Gatsby App is done on the client.
export const onInitialClientRender = () => {
  isPageReady();
};

export const onRouteUpdate = () => {
  const loader = document.getElementById("___loader");
  if (loader) {
    loader?.classList?.remove("brand-loader-hide");
  }
  isPageReady();
};
